import React, { Component } from 'react';
import Media from 'react-media';
import { func, number, object } from 'prop-types';
import isNil from 'lodash/isNil';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';

import { canUseExamDiff } from '../util/browser';

const tabCount = canUseExamDiff() ? 5 : 4;
const styles = theme => ({
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    textTransform: 'initial',
    backgroundColor: theme.palette.tab.unselected,
    width: `${100 / tabCount}%`,
    height: 48,
    minHeight: 48,
    color: 'white',
    maxWidth: 'unset'
  },
  tabRootMinimized: {
    textTransform: 'initial',
    backgroundColor: theme.palette.tab.unselected,
    width: `${100 / tabCount}%`,
    height: 48,
    minHeight: 48,
    color: 'white',
    maxWidth: 'unset',
    boxShadow: 'inset 0px -5px #0D47A1'
  },
  tabBorder: {
    borderLeft: theme.palette.buttonBorder
  },
  tabLabel: {
    textTransform: 'uppercase'
  },
  tabSelected: {
    backgroundColor: theme.palette.tab.selected
  },
  tabTextColorInherit: {
    opacity: 1
  },
  hiddenTextLabelContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0
  },
  textInputLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      fontFamily: '"Material Icons"',
      content: '"textsms"',
      fontSize: 24,
      marginRight: 24
    }
  },
  fileUploadLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      fontFamily: '"Material Icons"',
      content: '"file_upload"',
      fontSize: 24,
      marginRight: 24
    }
  },
  urlsLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      fontFamily: '"Material Icons"',
      content: '"link"',
      fontSize: 24,
      marginRight: 24
    }
  },
  foldersLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      fontFamily: '"Material Icons"',
      content: '"folder"',
      fontSize: 24,
      marginRight: 24
    }
  },
  samplesLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      fontFamily: '"Material Icons"',
      content: '"play_circle_filled"',
      fontSize: 24,
      marginRight: 24
    }
  }
});

const tabIndices = {
  'compare-clips': 0,
  'compare-files': 1,
  'compare-urls': 2,
  samples: 3
};

if (canUseExamDiff()) {
  tabIndices['compare-folders'] = 3;
  tabIndices['samples'] = 4;
}

class ComparisonTabs extends Component {
  state = {
    selectedTab: ComparisonTabs.tabIdxFromUrl()
  };

  static propTypes = {
    classes: object,
    lastComparedTabIndex: number,
    collapsedTabIndex: number,
    setCollapsedTabIndex: func,
    setCurrentTab: func
  };

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    selectedTab: ComparisonTabs.tabIdxFromUrl()
  });

  static tabIdxFromUrl = () => {
    const tabName = window.location.pathname.split('/')[1];
    return tabIndices[tabName];
  };

  onChangeTab = (event, newTabIndex) => {
    const sortedTabPaths = Object.keys(tabIndices).sort((a, b) => tabIndices[a] - tabIndices[b]);
    const currentTabPath = sortedTabPaths[newTabIndex];

    this.props.setCurrentTab(currentTabPath);
    this.setState({ selectedTab: newTabIndex });
    window.localStorage.setItem('diffNowLastViewedTab', `/${currentTabPath}`);
  };

  handleTabPress = tabIndex => {
    if (ComparisonTabs.tabIdxFromUrl() === tabIndex) {
      if (tabIndex === this.props.collapsedTabIndex) {
        this.props.setCollapsedTabIndex(null);
      } else {
        this.props.setCollapsedTabIndex(tabIndex);
      }
    } else if (this.props.collapsedTabIndex !== null) {
      this.props.setCollapsedTabIndex(ComparisonTabs.tabIdxFromUrl());
    }
  };

  render() {
    const { selectedTab } = this.state;
    const { collapsedTabIndex, lastComparedTabIndex, classes } = this.props;

    const getTabRootClass = tabIndex => {
      const classNames = [];

      if (tabIndex === lastComparedTabIndex) {
        classNames.push(classes.tabRootMinimized);
      } else {
        classNames.push(classes.tabRoot);
      }

      if (tabIndex > 0) {
        classNames.push(classes.tabBorder);
      }

      return classNames.join(' ');
    };

    const getTabLabelContainerClass = icon => {
      switch (icon) {
        case 'textInput':
          return classes.textInputLabelContainer;
        case 'fileUpload':
          return classes.fileUploadLabelContainer;
        case 'urls':
          return classes.urlsLabelContainer;
        case 'folders':
          return classes.foldersLabelContainer;
        case 'samples':
          return classes.samplesLabelContainer;
        default:
          return classes.textInputLabelContainer;
      }
    };

    const getTabClass = (icon, tabIndex, hideText) => ({
      wrapper: classes.tabWrapper,
      root: getTabRootClass(tabIndex),
      selected:
        tabIndex !== collapsedTabIndex && tabIndex === selectedTab ? classes.tabSelected : '',
      textColorInherit: classes.tabTextColorInherit,
      labelContainer: hideText ? classes.hiddenTextLabelContainer : getTabLabelContainerClass(icon),
      label: classes.tabLabel
    });

    return (
      <Amplitude>
        {({ logEvent }) => (
          <Media
            queries={{
              small: '(max-width: 874px)',
              large: '(min-width: 875px)'
            }}
          >
            {matches => (
              <Tabs
                value={isNil(selectedTab) ? lastComparedTabIndex : selectedTab}
                onChange={this.onChangeTab}
                indicatorColor="primary"
                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
              >
                <Tab
                  id="compare-clips-tab"
                  label={matches.large && 'Text Input'}
                  classes={getTabClass('textInput', 0, !matches.large)}
                  onClick={() => this.handleTabPress(0)}
                  component={Link}
                  to="/compare-clips"
                />
                <Tab
                  id="compare-files-tab"
                  label={matches.large && 'File Upload'}
                  classes={getTabClass('fileUpload', 1, !matches.large)}
                  onClick={() => this.handleTabPress(1)}
                  component={Link}
                  to="/compare-files"
                />
                <Tab
                  id="compare-urls-tab"
                  label={matches.large && 'URLs'}
                  classes={getTabClass('urls', 2, !matches.large)}
                  onClick={() => this.handleTabPress(2)}
                  component={Link}
                  to="/compare-urls"
                />
                {canUseExamDiff() && (
                  <Tab
                    id="compare-folders-tab"
                    label={matches.large && 'Folders'}
                    classes={getTabClass('folders', 3, !matches.large)}
                    onClick={() => {
                      logEvent('NAVIGATED_TO_FOLDERS_TAB');
                      this.handleTabPress(3);
                    }}
                    component={Link}
                    to="/compare-folders"
                  />
                )}
                <Tab
                  id="compare-samples-tab"
                  label={matches.large && 'Samples'}
                  classes={getTabClass('samples', canUseExamDiff() ? 4 : 3, !matches.large)}
                  onClick={() => this.handleTabPress(canUseExamDiff() ? 4 : 3)}
                  component={Link}
                  to="/samples"
                />
              </Tabs>
            )}
          </Media>
        )}
      </Amplitude>
    );
  }
}

export default withStyles(styles)(ComparisonTabs);
