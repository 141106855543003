import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import PropTypes, { object, number, func, string, array, bool } from 'prop-types';
import Helmet from 'react-helmet';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import map from 'lodash/map';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import download from 'downloadjs';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';
import queryString from 'query-string';
import compareVersions from 'compare-versions';
import amplitude from 'amplitude-js';
import { Amplitude } from '@amplitude/react-amplitude';

import { getConfiguration, getChangeLog } from '../util/util';
import { unrollReport, getReportMetadata, generateReportUrl, getReport } from '../util/reports';
import { checkIfLoggedIn } from '../util/user';
import { addNewSubscription } from '../util/subscription';
import {
  truncatedPrefix,
  truncatedSuffixPremium,
  truncatedSuffixNonPremium,
  getRandomTip
} from '../util/status';
import {
  getOptionsProfiles,
  getCurrentUserOptions,
  getUserOptionsByName,
  setCurrentProfile,
  deleteUserOptionsByName,
  getDefaultOptions
} from '../util/options';
import * as actions from '../actions/global';
import {
  DIFFNOW_URL,
  IS_CURRENT_PROFILE,
  SUCCESS_MESSAGE_DELAY_MS,
  COMPARE_CLIPS,
  COMPARE_FILES,
  COMPARE_URLS
} from '../util/constants';
import { canUserCompare, examDiffProUrl, isPremium, toDateString } from '../util/common';
import { areAdsInvisible, logAnalytics, getOS, canUseExamDiff } from '../util/browser';
import {
  compareClips,
  compareFiles,
  compareUrls,
  executePostComparisonActions
} from '../util/compare';
import {
  bumpComparisonCounter,
  comparisonLimitReachedStatus,
  setUpComparisonCounter,
  comparisonsUsedStatus
} from '../util/comparisonCount';
import { reconnectSignalR } from '../util/signalr';
import edpLogoSmall from '../edp-small.png';
import ErrorBoundary from '../components/ErrorBoundary';
import Status from '../components/Status';
import ComparisonAnimation from '../components/comparison/ComparisonAnimation';
import OutputFrame from '../components/comparison/OutputFrame';
import AdContainer from '../components/AdContainer';
import AdBlockMessage from '../components/AdBlockMessage';
import Footer from '../components/Footer';
import ConsentFooter from '../components/ConsentFooter';
import TopButton from '../components/TopButton';
import LoginDialog from '../components/users/LoginDialog';
import SavedReportsDialog from '../components/users/SavedReportsDialog';
import RegisterConfirmDialog from '../components/users/RegisterConfirmDialog';
import OptionsDialog from '../components/dialogs/OptionsDialog';
import AboutDialog from '../components/dialogs/AboutDialog';
import WhyPremiumDialog from '../components/dialogs/WhyPremiumDialog';
import CheckoutDialog from '../components/dialogs/CheckoutDialog';
import ChangePasswordDialog from '../components/dialogs/ChangePasswordDialog';
import ChangePasswordForResetDialog from '../components/dialogs/ChangePasswordForResetDialog';
import ManagePremiumSubscriptionDialog from '../components/dialogs/ManagePremiumSubscriptionDialog';
import TermsOfServiceDialog from '../components/dialogs/TermsOfServiceDialog';
import ContactDialog from '../components/dialogs/ContactDialog';
import StoreResultsDialog from '../components/dialogs/StoreResultsDialog';
import ActivationDialog from '../components/dialogs/ActivationDialog';
import ChangeLogDialog from '../components/dialogs/ChangeLogDialog';

import TitleBar from './TitleBar';
import ComparisonTabs from './ComparisonTabs';
import CompareClips from './CompareClips';
import CompareFiles from './CompareFiles';
import CompareUrls from './CompareUrls';
import CompareFolders from './CompareFolders';
import Samples from './Samples';

const DIFFNOW_SIGNALR_URL = `${DIFFNOW_URL}/signalr`;

function mapStateToProps(state) {
  return {
    nightModeEnabled: state.global.theme === 'dark',
    renderId: state.global.renderId,
    status: state.global.status,
    isComparing: state.global.isComparing,
    isTruncated: state.global.isTruncated,
    isReportReady: state.global.isReportReady,
    reportId: state.global.reportId,
    durationKey: state.global.durationKey,
    examDiffUri: state.global.examDiffUri,
    firstTitle: state.global.firstTitle,
    secondTitle: state.global.secondTitle,
    user: state.global.user,
    optionsProfiles: state.global.optionsProfiles,
    options: state.global.options,
    newOptions: state.global.newOptions,
    clientId: state.global.clientId,
    defaultOptions: state.global.defaultOptions,
    optionsLoaded: state.global.optionsLoaded,
    configuration: state.global.configuration,
    comparisonCount: state.global.comparisonCount,
    comparisonStartDate: state.global.comparisonStartDate,
    lastComparedTabIndex: state.global.lastComparedTabIndex,
    collapsedTabIndex: state.global.collapsedTabIndex,
    premiumSource: state.global.premiumSource
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoggedIn(user) {
      dispatch(actions.loggedIn(user));
    },
    onLoggedOut() {
      dispatch(actions.loggedOut());
    },
    onSetClipTitles(firstTitle, secondTitle) {
      dispatch(actions.setClipTitles(firstTitle, secondTitle));
    },
    onSetClips(firstClip, secondClip) {
      dispatch(actions.setClips(firstClip, secondClip));
    },
    onSetDocType(docType) {
      dispatch(actions.setDocType(docType));
    },
    onSetFileTitles(firstTitle, secondTitle) {
      dispatch(actions.setFileTitles(firstTitle, secondTitle));
    },
    onSetUrls(firstUrl, secondUrl) {
      dispatch(actions.setUrls(firstUrl, secondUrl));
    },
    onSetUrlTitles(firstTitle, secondTitle) {
      dispatch(actions.setUrlTitles(firstTitle, secondTitle));
    },
    onSubscribeUser() {
      dispatch(actions.subscribeUser());
    },
    onSetStatus(status) {
      dispatch(actions.setStatus(status));
    },
    onSetReportId(reportId) {
      dispatch(actions.setReport(reportId, undefined));
    },
    onSetExamDiffUri(examDiffUri) {
      dispatch(actions.setExamDiffUri(examDiffUri));
    },
    onSetComparisonCount(newComparisonCount, newComparisonStartDate) {
      dispatch(actions.setComparisonCount(newComparisonCount, newComparisonStartDate));
    },
    onSetLastComparedTabIndex(newLastComparedTabIndex) {
      dispatch(actions.setLastComparedTabIndex(newLastComparedTabIndex));
    },
    onSetCollapsedTabIndex(newCollapsedTabIndex) {
      dispatch(actions.setCollapsedTabIndex(newCollapsedTabIndex));
    },
    onSetClientId(clientId) {
      dispatch(actions.setClientId(clientId));
    },
    onSetOptionsProfiles(optionsProfiles) {
      dispatch(actions.setOptionsProfiles(optionsProfiles));
    },
    onSetOptions(options) {
      dispatch(actions.setOptions(options));
    },
    onSetNewOption(newOption) {
      dispatch(actions.setNewOption(newOption));
    },
    onSaveNewOptions() {
      dispatch(actions.saveNewOptions());
    },
    onResetNewOptions() {
      dispatch(actions.resetNewOptions());
    },
    onSetDefaultOptions(defaultOptions) {
      dispatch(actions.setDefaultOptions(defaultOptions));
    },
    onSetTruncatedStatus(truncatedStatus) {
      dispatch(actions.setTruncatedStatus(truncatedStatus));
    },
    onSetConfiguration(configuration) {
      dispatch(actions.setConfiguration(configuration));
    },
    onSetReport(reportId, durationKey) {
      dispatch(actions.setReport(reportId, durationKey));
    },
    onSetTheme(newTheme) {
      dispatch(actions.setTheme(newTheme));
    },
    onSetPremiumSource(premiumSource) {
      dispatch(actions.setPremiumSource(premiumSource));
    },
    onSetReportReady(isReportReady) {
      dispatch(actions.setReportReady(isReportReady));
    }
  };
}

export class App extends Component {
  static propTypes = {
    nightModeEnabled: bool,
    history: object,
    renderId: number, // eslint-disable-line react/no-unused-prop-types
    status: object,
    isComparing: bool,
    isTruncated: bool,
    isReportReady: bool,
    reportId: string,
    durationKey: string,
    examDiffUri: string,
    firstTitle: string,
    secondTitle: string,
    onLoggedIn: func,
    onLoggedOut: func,
    onSetClipTitles: func,
    onSetClips: func,
    onSetDocType: func,
    onSetFileTitles: func,
    onSetUrls: func,
    onSetUrlTitles: func,
    onSetExamDiffUri: func,
    onSubscribeUser: func,
    onSetStatus: func,
    onSetClientId: func,
    onSetOptionsProfiles: func,
    onSetOptions: func,
    onSetNewOption: func,
    onSaveNewOptions: func,
    onResetNewOptions: func,
    onSetDefaultOptions: func,
    onSetTruncatedStatus: func,
    onSetComparisonCount: func,
    onSetConfiguration: func,
    onSetCollapsedTabIndex: func,
    onSetReportId: func,
    onSetReport: func,
    onSetTheme: func,
    onSetPremiumSource: func,
    onSetReportReady: func,
    user: object,
    optionsProfiles: array,
    options: object,
    newOptions: object,
    defaultOptions: object,
    clientId: string,
    optionsLoaded: bool,
    configuration: object,
    comparisonCount: number,
    comparisonStartDate: PropTypes.instanceOf(Date),
    lastComparedTabIndex: number,
    collapsedTabIndex: number,
    premiumSource: string,
    theme: object
  };

  state = {
    loading: false,
    activationConfirmationMessage: '',
    changePasswordConfirmationKey: null,
    changePasswordEmailAddress: null,

    currentTab: 'compare-clips',

    areAdsInvisible: false,
    isConsentFooterDismissed: false,
    currentVersion: null,
    changeLog: [],

    lastComparisonPayload: null,

    isLoginDialogOpen: false,
    isRegisterConfirmDialogOpen: false,
    registerConfirmDialogEmail: '',
    isSavedReportsDialogOpen: false,
    isOptionsDialogOpen: false,
    isAboutDialogOpen: false,
    isWhyPremiumDialogOpen: false,
    isCheckoutDialogOpen: false,
    isChangePasswordDialogOpen: false,
    isManagePremiumSubscriptionDialogOpen: false,
    isTermsOfServiceDialogOpen: false,
    isContactDialogOpen: false,
    isStoreResultsDialogOpen: false,
    isActivationDialogOpen: false,
    isChangePasswordForResetDialogOpen: false,
    isChangeLogDialogOpen: false
  };

  componentDidMount() {
    this.onAppStart();
  }

  onAppStart = async () => {
    const clientId = uuidv4();

    this.setUpConsentFooter();
    this.setUpTheme();
    this.checkForActivation();
    this.checkForChangePassword();
    this.checkForChangeLog();
    this.setUpSignalR(clientId);

    const sessionToken = window.localStorage.getItem('diffNowSessionToken');
    if (sessionToken) {
      const loginResponse = await checkIfLoggedIn(sessionToken);
      this.onUserLogin(loginResponse.data.user, sessionToken);
    } else {
      this.onUserLogout(sessionToken, true);
    }

    const identify = new amplitude.Identify().setOnce('user_os', getOS());
    amplitude.getInstance().identify(identify);

    logAnalytics();
    this.checkPath();
    this.setUpConfigurationVariables();
    this.props.onSetClientId(clientId);
    this.props.onSetStatus({
      message: getRandomTip(),
      type: 'tip'
    });

    setInterval(() => {
      this.setState({ areAdsInvisible: areAdsInvisible() });
    }, 1000);
  };

  setUpConfigurationVariables = async () => {
    const configurationData = await getConfiguration();
    this.props.onSetConfiguration(configurationData);
    setUpComparisonCounter(this.props.onSetComparisonCount, configurationData);
  };

  setUpSignalR = clientId => {
    window.jQuery = $;
    require('../lib/jquery.signalR.min.js'); // eslint-disable-line
    require('../signalr/hubs.js'); // eslint-disable-line

    const connectionHub = $.connection.diffnowHub;

    connectionHub.client.sendMessage = (type, message) => {
      const {
        isTruncated,
        user,
        comparisonCount,
        configuration,
        onSetStatus,
        onSetTruncatedStatus,
        onSetReportReady
      } = this.props;

      if (message.includes('Truncating')) {
        onSetTruncatedStatus(true);
      } else if (message.includes('Comparing')) {
        onSetTruncatedStatus(false);
      }

      onSetStatus({
        message: decodeURIComponent(message),
        type: type.toLowerCase()
      });

      if (type === 'Success') {
        window.setTimeout(() => {
          onSetReportReady(true);
          onSetStatus({ message: null, type: null }); // Clear status first (this is important for some logic).

          if (isTruncated) {
            const truncatedSuffix = isPremium(user)
              ? truncatedSuffixPremium
              : truncatedSuffixNonPremium;
            onSetStatus({
              message: `${truncatedPrefix} ${this.props.options.lcl} ${truncatedSuffix}`,
              type: 'error'
            });
          } else {
            onSetStatus(comparisonsUsedStatus(comparisonCount, user, configuration));
          }
        }, SUCCESS_MESSAGE_DELAY_MS);
      }
    };

    $.connection.hub.url = DIFFNOW_SIGNALR_URL;
    $.connection.hub.qs = `sessionId=${clientId}`;
    $.connection.hub.start();
  };

  hideStatusAfterDelay = delay => {
    window.setTimeout(() => {
      this.props.onSetStatus({ message: null, type: null });
    }, delay);
  };

  setUpConsentFooter = () => {
    this.setState({
      isConsentFooterDismissed: JSON.parse(window.localStorage.getItem('diffNowFooterDismissed'))
    });
  };

  setUpTheme = () => {
    const theme = window.localStorage.getItem('diffNowTheme');
    this.props.onSetTheme(isNil(theme) ? 'light' : theme);
  };

  checkPath = () => {
    if (window.location.pathname === '/') {
      this.redirectToLastViewedPage();
    } else if (window.location.pathname.startsWith('/report')) {
      const reportId = window.location.pathname.split('/')[2];
      this.openReport(reportId);
    } else if (window.location.pathname.startsWith('/terms-of-service')) {
      this.redirectToLastViewedPage();
      this.toggleDialog('isTermsOfServiceDialogOpen');
    }

    this.setState({ currentTab: window.location.pathname.replace('/', '') });
  };

  redirectToLastViewedPage = () => {
    const lastViewedTab = window.localStorage.getItem('diffNowLastViewedTab');

    if (lastViewedTab) {
      this.setState({ currentTab: lastViewedTab.replace('/', '') });
      this.props.history.replace(`${lastViewedTab}${window.location.search}`);
    } else {
      this.setState({ currentTab: 'compare-clips' });
      this.redirectToCompareClips();
    }
  };

  openReport = async reportShortUrl => {
    if (reportShortUrl) {
      try {
        const { reportId, durationKey } = await unrollReport(reportShortUrl);
        this.handleReportSuccess(reportId, durationKey, reportShortUrl);
      } catch (error) {
        this.redirectToCompareClips();
      }
    } else {
      this.redirectToCompareClips();
    }
  };

  handleReportSuccess = async (reportId, durationKey, reportShortUrl) => {
    if (reportId !== 'not found') {
      getReportMetadata(
        reportShortUrl,
        reportMetadata => {
          const creationTime = toDateString(reportMetadata['GenerationTimeString']);
          const expirationTimeString =
            reportMetadata['ExpirationTime'] === null
              ? ''
              : `It expires on ${toDateString(reportMetadata['ExpirationTimeString'])}.`;
          const reportTitle = reportMetadata['Title'];

          this.props.onSetReportReady(true);
          this.props.onSetReport(reportId, durationKey);
          this.props.onSetStatus({
            message: `Report "${reportTitle}" was generated on ${creationTime}. ${expirationTimeString}`,
            type: 'info'
          });
        },
        error => {
          this.props.onSetStatus({
            message: error,
            type: 'error'
          });
        }
      );
    } else {
      this.redirectToCompareClips();
    }
  };

  redirectToCompareClips = () => {
    this.props.history.replace(`/compare-clips${window.location.search}`);
  };

  checkForActivation = () => {
    const params = queryString.parse(window.location.search);
    let message = null;

    if (params.activated && JSON.parse(params.activated)) {
      message =
        'Congratulations! Your DiffNow account has been created. You can now log in with your email and password.';
    } else if (params.activated && !JSON.parse(params.activated)) {
      if (params.error) {
        message = `Error: ${
          params.error
        }. Please try again, or contact us if this problem persists`;
      } else {
        message =
          'A bad confirmation key was given. Please try again, or contact us if this problem persists.';
      }
    }

    if (message) {
      this.setState({
        activationConfirmationMessage: message,
        isActivationDialogOpen: true
      });
    }
  };

  checkForChangePassword = () => {
    const params = queryString.parse(window.location.search);

    if (params.changePassword && JSON.parse(params.changePassword)) {
      this.setState({
        isChangePasswordForResetDialogOpen: true,
        changePasswordEmailAddress: params.emailAddress,
        changePasswordConfirmationKey: params.confirmationKey
      });
    }
  };

  checkForChangeLog = () => {
    const storedCurrentVersion = window.localStorage.getItem('diffNowCurrentVersion');

    getChangeLog(changeLog => {
      const sortedVersions = changeLog.sort((changesA, changesB) =>
        compareVersions(changesB.version, changesA.version)
      );
      const newestVersion = sortedVersions[0].version;

      this.setState({
        currentVersion: newestVersion,
        changeLog: sortedVersions
      });

      if (newestVersion !== storedCurrentVersion) {
        this.setState({
          isChangeLogDialogOpen: true
        });
      }
    }, console.error);
  };

  markChangeLogViewed = version => {
    window.localStorage.setItem('diffNowCurrentVersion', version);
  };

  /* USER HELPERS */

  onUserLogin = (user, sessionToken) => {
    if (user.IsLoggedIn) {
      this.props.onLoggedIn({
        email: user.EmailAddress,
        sessionToken: sessionToken,
        isPremium: user.IsPremium
      });

      amplitude.getInstance().setUserId(user.EmailAddress);
      amplitude.getInstance().setUserProperties({
        user_email: user.EmailAddress,
        user_is_premium: user.IsPremium
      });

      window.localStorage.setItem('diffNowSessionToken', sessionToken);

      this.loadUserOptions(sessionToken);
    } else {
      this.onUserLogout(sessionToken);
    }
  };

  onUserLogout = async sessionToken => {
    this.props.onLoggedOut();

    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().clearUserProperties();

    getDefaultOptions(sessionToken, defaultOptions => {
      this.props.onSetDefaultOptions(defaultOptions);
      this.props.onSetOptions(defaultOptions);
      this.props.onSetOptionsProfiles([
        {
          options_name: 'Default Profile',
          current_profile: 1
        }
      ]);
    });
  };

  onSubscribe = (subscriptionToken, plan, source) => {
    const sessionToken = window.localStorage.getItem('diffNowSessionToken');
    addNewSubscription(
      sessionToken,
      subscriptionToken.id,
      plan,
      source,
      () => {
        this.props.onSubscribeUser();
        this.toggleDialog('isWhyPremiumDialogOpen');
        this.toggleDialog('isCheckoutDialogOpen');
      },
      console.error
    );
  };

  /* COMPARISON */

  onCompare = async (type, comparisonPayload) => {
    const { comparisonCount, user, configuration, onSetStatus } = this.props;

    await reconnectSignalR();

    if (canUserCompare(comparisonCount, user, configuration)) {
      this.startComparison(type, comparisonPayload);
    } else {
      onSetStatus(comparisonLimitReachedStatus(user, configuration));
    }
  };

  onRecompare = async () => {
    const {
      comparisonCount,
      user,
      configuration,
      onSetStatus,
      reportId,
      lastComparedTabIndex,
      options
    } = this.props;
    const { lastComparisonPayload } = this.state;

    if (reportId && lastComparisonPayload) {
      await reconnectSignalR();
      if (canUserCompare(comparisonCount, user, configuration)) {
        const newComparisonPayload = Object.assign({}, lastComparisonPayload, {
          optionsJson: options
        });
        this.startComparison(lastComparedTabIndex, newComparisonPayload);
      } else {
        onSetStatus(comparisonLimitReachedStatus(user, configuration));
      }
    }
  };

  startComparison = async (type, comparisonPayload) => {
    const {
      onSetClipTitles,
      onSetClips,
      onSetDocType,
      onSetFileTitles,
      onSetUrls,
      onSetUrlTitles,
      onSetReportReady
    } = this.props;

    this.setState({ lastComparisonPayload: comparisonPayload });

    if (type === COMPARE_CLIPS) {
      onSetClipTitles(comparisonPayload.title1, comparisonPayload.title2);
      onSetClips(comparisonPayload.clip1, comparisonPayload.clip2);
      onSetDocType(comparisonPayload.clipType);
    } else if (type === COMPARE_FILES) {
      onSetFileTitles(comparisonPayload.originalFileName1, comparisonPayload.originalFileName2);
    } else if (type === COMPARE_URLS) {
      const getTitleFromUrl = title => title.substring(title.lastIndexOf('/') + 1);

      onSetUrls(comparisonPayload.url1, comparisonPayload.url2);
      onSetUrlTitles(
        getTitleFromUrl(comparisonPayload.url1),
        getTitleFromUrl(comparisonPayload.url2)
      );
    }

    comparisonPayload.useQueue = true;
    comparisonPayload.adBlockDetected = areAdsInvisible();

    const compareFn = [compareClips, compareFiles, compareUrls][type];
    try {
      onSetReportReady(false);
      const { reportId, examDiffUri } = await compareFn(comparisonPayload);
      this.onCompareSuccess(type, reportId, examDiffUri);
    } catch (error) {
      this.onCompareError(error);
    }
  };

  onCompareSuccess = (type, reportId, examDiffUri) => {
    const { onSetReportId, onSetExamDiffUri } = this.props;

    onSetReportId(reportId);
    onSetExamDiffUri(examDiffUri);
    bumpComparisonCounter(this.props);
    executePostComparisonActions(this.props, type);
  };

  onCompareError = message => {
    this.props.onSetStatus({
      message: message,
      type: 'error'
    });
  };

  /* OPTIONS HELPERS */

  loadUserOptions = sessionToken => {
    getDefaultOptions(sessionToken, defaultOptions => {
      this.props.onSetDefaultOptions(defaultOptions);
      getOptionsProfiles(sessionToken, this.props.onSetOptionsProfiles);
      getCurrentUserOptions(sessionToken, userOptions => {
        this.props.onSetOptions(merge({}, defaultOptions, userOptions));
      });
    });
  };

  getSelectedOptionsProfiles = (optionsProfiles, selectedProfile) =>
    map(optionsProfiles, prevProfile => {
      if (prevProfile.options_name === selectedProfile) {
        return Object.assign({}, prevProfile, { current_profile: 1 });
      } else {
        if (prevProfile.current_profile === IS_CURRENT_PROFILE) {
          return Object.assign({}, prevProfile, { current_profile: 0 });
        } else {
          return prevProfile;
        }
      }
    });

  getDeletedOptionsProfiles = (optionsProfiles, deletedProfile) =>
    optionsProfiles.filter(optionsProfile => optionsProfile.options_name !== deletedProfile);

  onProfileSelect = profile => {
    setCurrentProfile(this.props.user.sessionToken, profile);
    getUserOptionsByName(this.props.user.sessionToken, profile, userOptions => {
      this.props.onSetOptions(merge({}, this.props.defaultOptions, userOptions));
      this.props.onSetOptionsProfiles(
        this.getSelectedOptionsProfiles(this.props.optionsProfiles, profile)
      );
    });
  };

  onProfileDelete = profile => {
    deleteUserOptionsByName(this.props.user.sessionToken, profile, deletedProfile => {
      setCurrentProfile(this.props.user.sessionToken, 'Default Profile');
      getUserOptionsByName(this.props.user.sessionToken, 'Default Profile', userOptions => {
        this.props.onSetOptions(merge({}, this.props.defaultOptions, userOptions));
        this.props.onResetNewOptions();
        this.props.onSetOptionsProfiles(
          this.getSelectedOptionsProfiles(
            this.getDeletedOptionsProfiles(this.props.optionsProfiles, deletedProfile),
            'Default Profile'
          )
        );
      });
    });
  };

  onProfileSaveAs = profile => {
    const newProfiles = this.props.optionsProfiles;
    newProfiles.push({
      options_name: profile,
      current_profile: 1
    });

    this.props.onSetOptionsProfiles(
      map(newProfiles, prevProfile => {
        if (prevProfile.options_name !== profile) {
          return Object.assign({}, prevProfile, { current_profile: 0 });
        } else {
          return prevProfile;
        }
      })
    );

    setCurrentProfile(this.props.user.sessionToken, profile);
  };

  toggleNightMode = () => {
    const newTheme = this.props.nightModeEnabled ? 'light' : 'dark';
    this.props.onSetTheme(newTheme);
    window.localStorage.setItem('diffNowTheme', newTheme);
  };

  getDescriptionContent = () => {
    const { currentTab } = this.state;

    switch (currentTab) {
      case 'compare-clips':
        return 'Paste clipboard contents to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
      case 'compare-files':
        return 'Upload files to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
      case 'compare-urls':
        return 'Provide URLs to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
      case 'compare-folders':
        return 'Upload folders as archives to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
      case 'samples':
        return 'Try a set of example files to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
      default:
        return 'Upload files, provide URLs, and paste clipboard contents to compare content online. Powered by ExamDiff Pro, the most powerful desktop comparison and merge tool.';
    }
  };

  /* DIALOG HELPERS */

  toggleDialog = (dialogState, otherState) => {
    this.setState(prevState =>
      Object.assign({}, { [dialogState]: !prevState[dialogState] }, otherState)
    );
  };

  renderReportOperations = hideLabels => {
    const { reportId, durationKey, examDiffUri, user } = this.props;

    if (reportId) {
      return (
        <Fragment>
          {examDiffUri && (
            <Amplitude>
              {({ logEvent }) => (
                <TopButton
                  hideIcon
                  label={!hideLabels && 'Open in ExamDiff Pro'}
                  rightElement={
                    canUseExamDiff() ? (
                      <img
                        src={edpLogoSmall}
                        alt="ExamDiff Pro"
                        style={{ marginLeft: hideLabels ? 0 : 10 }}
                      />
                    ) : null
                  }
                  icon={!hideLabels && 'open_in_new'}
                  operation={() => {
                    logEvent('DIFFNOW_OPEN_IN_EDP');
                    window.open(examDiffUri, '_self');
                  }}
                  disabled={!canUseExamDiff()}
                  tooltip="This feature is Windows-only and requires ExamDiff Pro 10.0.1.6 or above to be installed on your system."
                />
              )}
            </Amplitude>
          )}
          <Amplitude>
            {({ logEvent }) => (
              <TopButton
                label={!hideLabels && 'Fullscreen'}
                icon="fullscreen"
                operation={() => {
                  logEvent('CLICKED_FULLSCREEN');
                  window.open(generateReportUrl(reportId, durationKey));
                }}
                noMarginRight
              />
            )}
          </Amplitude>
          <TopButton
            label={!hideLabels && 'Save/Share'}
            icon="share"
            operation={() => this.toggleDialog('isStoreResultsDialogOpen')}
            disabled={user === null}
            mustBeLoggedIn
            noMarginRight
          />
          <Amplitude>
            {({ logEvent }) => (
              <TopButton
                label={!hideLabels && 'Download'}
                icon="file_download"
                operation={async () => {
                  logEvent('CLICKED_DOWNLOAD');
                  const reportData = await getReport(reportId, durationKey);
                  download(reportData, `${reportId}.html`);
                }}
              />
            )}
          </Amplitude>
        </Fragment>
      );
    }
  };

  get topBar() {
    const { theme, reportId } = this.props;

    return (
      <Media
        queries={{
          smallNoReport: '(max-width: 719px)',
          smallWithReport: '(min-width: 720px) and (max-width: 1129px)',
          large: '(min-width: 1130px)'
        }}
      >
        {matches => {
          const hideLabels =
            (reportId && (matches.smallWithReport || matches.smallNoReport)) ||
            (!reportId && matches.smallNoReport);
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20
              }}
            >
              <div style={{ display: 'flex' }}>
                <Amplitude>
                  {({ logEvent }) => (
                    <Button
                      variant="raised"
                      color="secondary"
                      href={examDiffProUrl('diffnow-header')}
                      onClick={() => logEvent('CLICKED_POWERED_BY_EXAMDIFF_PRO')}
                      target="_blank"
                      style={{
                        display: reportId ? 'none' : 'flex',
                        border: theme.palette.buttonBorder,
                        color: this.props.nightModeEnabled ? '#ffffff' : '#000000',
                        backgroundColor: this.props.nightModeEnabled ? '#0d47a1' : '#ffee58'
                      }}
                      rel="noopener noreferrer"
                    >
                      {!hideLabels && 'Powered by ExamDiff Pro'}
                      <img
                        src={edpLogoSmall}
                        alt="ExamDiff Pro"
                        style={{ marginLeft: !hideLabels ? 10 : 0 }}
                      />
                    </Button>
                  )}
                </Amplitude>
                {this.renderReportOperations(hideLabels)}
              </div>
              <div style={{ display: 'flex' }}>
                <Amplitude>
                  {({ logEvent }) => (
                    <TopButton
                      label={!hideLabels && 'Options'}
                      icon="build"
                      operation={() => {
                        logEvent('OPEN_OPTIONS_DIALOG', { source: 'TOP_BAR' });
                        this.toggleDialog('isOptionsDialogOpen');
                      }}
                      disabled={!this.props.optionsLoaded}
                      noMarginRight
                    />
                  )}
                </Amplitude>
                <TopButton
                  noMarginRight
                  label={!hideLabels && 'About'}
                  icon="info"
                  operation={() => this.toggleDialog('isAboutDialogOpen')}
                />
              </div>
            </div>
          );
        }}
      </Media>
    );
  }

  get content() {
    const showAdBlockHiddenMessage = !isPremium(this.props.user) && this.state.areAdsInvisible;
    const hideAdContainer = isPremium(this.props.user) || this.state.areAdsInvisible;

    if (this.state.loading) {
      return null;
    } else {
      return (
        <ErrorBoundary
          openContactDialog={() => {
            this.toggleDialog('isContactDialogOpen');
          }}
        >
          <div style={{ margin: '20px 72px' }}>
            {showAdBlockHiddenMessage && (
              <Amplitude>
                {({ logEvent }) => (
                  <AdBlockMessage
                    openWhyPremiumDialog={source => {
                      logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'AD_BLOCK_MESSAGE' });
                      this.props.onSetPremiumSource(source);
                      this.toggleDialog('isWhyPremiumDialogOpen');
                    }}
                  />
                )}
              </Amplitude>
            )}
            <AdContainer adSlotId="4808589960" hideAdContainer={hideAdContainer} />
            {this.topBar}
            <ComparisonTabs
              setCurrentTab={currentTab => this.setState({ currentTab })}
              lastComparedTabIndex={this.props.lastComparedTabIndex}
              collapsedTabIndex={this.props.collapsedTabIndex}
              setCollapsedTabIndex={this.props.onSetCollapsedTabIndex}
            />
            <Switch>
              <Route
                path="/compare-clips"
                render={props => (
                  <Amplitude>
                    {({ logEvent }) => (
                      <CompareClips
                        {...props}
                        onCompare={this.onCompare}
                        onSetCollapsedTabIndex={this.props.onSetCollapsedTabIndex}
                        openWhyPremiumDialog={() => {
                          logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'TEXT_INPUT_PANE' });
                          this.props.onSetPremiumSource('inputpane-text');
                          this.toggleDialog('isWhyPremiumDialogOpen');
                        }}
                      />
                    )}
                  </Amplitude>
                )}
                history={this.props.history}
              />
              <Route
                path="/compare-files"
                render={props => (
                  <Amplitude>
                    {({ logEvent }) => (
                      <CompareFiles
                        {...props}
                        onCompare={this.onCompare}
                        onSetCollapsedTabIndex={this.props.onSetCollapsedTabIndex}
                        openWhyPremiumDialog={() => {
                          logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'FILE_INPUT_PANE' });
                          this.props.onSetPremiumSource('inputpane-files');
                          this.toggleDialog('isWhyPremiumDialogOpen');
                        }}
                      />
                    )}
                  </Amplitude>
                )}
                history={this.props.history}
              />
              <Route
                path="/compare-urls"
                render={props => (
                  <Amplitude>
                    {({ logEvent }) => (
                      <CompareUrls
                        {...props}
                        onCompare={this.onCompare}
                        onSetCollapsedTabIndex={this.props.onSetCollapsedTabIndex}
                        openWhyPremiumDialog={() => {
                          logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'URL_INPUT_PANE' });
                          this.props.onSetPremiumSource('inputpane-urls');
                          this.toggleDialog('isWhyPremiumDialogOpen');
                        }}
                      />
                    )}
                  </Amplitude>
                )}
                history={this.props.history}
              />
              <Route path="/compare-folders" component={CompareFolders} />
              <Route path="/samples" component={Samples} />
            </Switch>
            <Amplitude>
              {({ logEvent }) => (
                <Status
                  id={this.props.clientId}
                  message={this.props.status.message}
                  type={this.props.status.type}
                  openWhyPremiumDialog={source => {
                    this.props.onSetPremiumSource(source);
                    logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'STATUS_BAR' });
                    this.toggleDialog('isWhyPremiumDialogOpen');
                  }}
                  openOptionsDialog={() => {
                    logEvent('OPEN_OPTIONS_DIALOG', { source: 'STATUS_BAR' });
                    this.toggleDialog('isOptionsDialogOpen');
                  }}
                  removeTip={() => {
                    this.props.onSetStatus({
                      message: null,
                      type: null
                    });
                  }}
                />
              )}
            </Amplitude>
            {this.props.isComparing && <ComparisonAnimation />}
            <OutputFrame
              reportId={this.props.reportId}
              durationKey={this.props.durationKey}
              isReportReady={this.props.isReportReady}
              nightModeEnabled={this.props.nightModeEnabled}
            />
            <AdContainer adSlotId="9678020683" hideAdContainer={hideAdContainer} />
            <Footer
              openTermsOfServiceDialog={() => {
                this.toggleDialog('isTermsOfServiceDialogOpen');
              }}
              openContactDialog={() => {
                this.toggleDialog('isContactDialogOpen');
              }}
              openChangeLogDialog={() => {
                this.toggleDialog('isChangeLogDialogOpen');
              }}
            />
          </div>
        </ErrorBoundary>
      );
    }
  }

  get dialogs() {
    if (!this.state.loading) {
      return (
        <div>
          <LoginDialog
            open={this.state.isLoginDialogOpen}
            onUserLogin={this.onUserLogin}
            closeDialog={() => this.toggleDialog('isLoginDialogOpen')}
            openRegisterConfirmDialog={email => {
              this.toggleDialog('isRegisterConfirmDialogOpen', {
                registerConfirmDialogEmail: email
              });
            }}
          />
          <RegisterConfirmDialog
            open={this.state.isRegisterConfirmDialogOpen}
            email={this.state.registerConfirmDialogEmail}
            closeDialog={() => {
              this.toggleDialog('isRegisterConfirmDialogOpen', { registerConfirmDialogEmail: '' });
            }}
          />
          <SavedReportsDialog
            open={this.state.isSavedReportsDialogOpen}
            onSetStatus={this.props.onSetStatus}
            sessionToken={this.props.user ? this.props.user.sessionToken : null}
            closeDialog={() => {
              this.toggleDialog('isSavedReportsDialogOpen');
            }}
          />
          <Amplitude>
            {({ logEvent }) => (
              <OptionsDialog
                isPremium={isPremium(this.props.user)}
                shouldPromptToRecompare={!!this.props.reportId}
                sessionToken={this.props.user ? this.props.user.sessionToken : null}
                options={this.props.options}
                newOptions={this.props.newOptions}
                defaultOptions={this.props.defaultOptions}
                optionsProfiles={this.props.optionsProfiles}
                nightModeEnabled={this.props.nightModeEnabled}
                onProfileSelect={this.onProfileSelect}
                onProfileDelete={this.onProfileDelete}
                onProfileSaveAs={this.onProfileSaveAs}
                setOptions={this.props.onSetOptions}
                setNewOption={this.props.onSetNewOption}
                onSaveNewOptions={this.props.onSaveNewOptions}
                onResetNewOptions={this.props.onResetNewOptions}
                onRecompare={this.onRecompare}
                open={this.state.isOptionsDialogOpen}
                openWhyPremiumDialog={() => {
                  logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'OPTIONS_DIALOG' });
                  this.props.onSetPremiumSource('options-dialog');
                  this.toggleDialog('isWhyPremiumDialogOpen');
                }}
                closeDialog={() => {
                  this.toggleDialog('isOptionsDialogOpen');
                }}
              />
            )}
          </Amplitude>
          <Amplitude>
            {({ logEvent }) => (
              <AboutDialog
                open={this.state.isAboutDialogOpen}
                configuration={this.props.configuration}
                openWhyPremiumDialog={() => {
                  logEvent('OPEN_WHY_PREMIUM_DIALOG', { source: 'OPTIONS_DIALOG' });
                  this.props.onSetPremiumSource('about-dialog');
                  this.toggleDialog('isWhyPremiumDialogOpen');
                }}
                closeDialog={() => {
                  this.toggleDialog('isAboutDialogOpen');
                }}
              />
            )}
          </Amplitude>
          <WhyPremiumDialog
            open={this.state.isWhyPremiumDialogOpen}
            configuration={this.props.configuration}
            openCheckoutDialog={() => {
              this.toggleDialog('isCheckoutDialogOpen');
            }}
            user={this.props.user}
            closeDialog={() => {
              this.toggleDialog('isWhyPremiumDialogOpen');
            }}
          />
          <CheckoutDialog
            open={this.state.isCheckoutDialogOpen}
            onSubscribe={this.onSubscribe}
            user={this.props.user}
            premiumSource={this.props.premiumSource}
            closeDialog={() => {
              this.toggleDialog('isCheckoutDialogOpen');
            }}
            openTermsOfService={() => {
              this.toggleDialog('isTermsOfServiceDialogOpen');
            }}
          />
          <ChangePasswordDialog
            open={this.state.isChangePasswordDialogOpen}
            closeDialog={() => {
              this.toggleDialog('isChangePasswordDialogOpen');
            }}
          />
          <ChangePasswordForResetDialog
            open={this.state.isChangePasswordForResetDialogOpen}
            emailAddress={this.state.changePasswordEmailAddress}
            confirmationKey={this.state.changePasswordConfirmationKey}
            closeDialog={() => {
              this.toggleDialog('isChangePasswordForResetDialogOpen');
            }}
          />
          <ManagePremiumSubscriptionDialog
            open={this.state.isManagePremiumSubscriptionDialogOpen}
            user={this.props.user}
            closeDialog={() => {
              this.toggleDialog('isManagePremiumSubscriptionDialogOpen');
            }}
          />
          <TermsOfServiceDialog
            open={this.state.isTermsOfServiceDialogOpen}
            closeDialog={() => {
              this.toggleDialog('isTermsOfServiceDialogOpen');
            }}
          />
          <ContactDialog
            open={this.state.isContactDialogOpen}
            closeDialog={() => {
              this.toggleDialog('isContactDialogOpen');
            }}
          />
          <StoreResultsDialog
            open={this.state.isStoreResultsDialogOpen}
            user={this.props.user}
            reportId={this.props.reportId}
            firstTitle={this.props.firstTitle}
            secondTitle={this.props.secondTitle}
            storeResults={this.storeResults}
            closeDialog={() => {
              this.toggleDialog('isStoreResultsDialogOpen');
            }}
          />
          <ActivationDialog
            open={this.state.isActivationDialogOpen}
            message={this.state.activationConfirmationMessage}
            closeDialog={() => {
              this.toggleDialog('isActivationDialogOpen');
            }}
          />
          <ChangeLogDialog
            open={this.state.isChangeLogDialogOpen}
            changeLog={this.state.changeLog}
            closeDialog={() => {
              this.toggleDialog('isChangeLogDialogOpen');
              this.markChangeLogViewed(this.state.currentVersion);
            }}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>DiffNow - Compare Files, URLs, and Clipboard Contents Online</title>
          <meta name="description" content={this.getDescriptionContent()} />
        </Helmet>
        <Amplitude>
          {({ logEvent }) => (
            <TitleBar
              user={this.props.user}
              optionsProfiles={this.props.optionsProfiles}
              onUserLogout={this.onUserLogout}
              onProfileSelect={this.onProfileSelect}
              toggleNightMode={this.toggleNightMode}
              openLoginDialog={() => this.toggleDialog('isLoginDialogOpen')}
              openSavedReportsDialog={() => this.toggleDialog('isSavedReportsDialogOpen')}
              openWhyPremiumDialog={source => {
                logEvent('OPEN_WHY_PREMIUM_DIALOG', { source });
                this.props.onSetPremiumSource('accountsbar-button');
                this.toggleDialog('isWhyPremiumDialogOpen');
              }}
              openChangePasswordDialog={() => this.toggleDialog('isChangePasswordDialogOpen')}
              openManagePremiumSubscriptionDialog={() =>
                this.toggleDialog('isManagePremiumSubscriptionDialogOpen')
              }
            />
          )}
        </Amplitude>
        {this.content}
        {this.dialogs}
        <ConsentFooter
          isDismissed={!!this.state.isConsentFooterDismissed}
          openTosDialog={() => this.toggleDialog('isTermsOfServiceDialogOpen')}
          dismiss={() => {
            window.localStorage.setItem('diffNowFooterDismissed', true);
            this.setState({ isConsentFooterDismissed: true });
          }}
        />
      </Fragment>
    );
  }
}

export default withTheme()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
